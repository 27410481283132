.container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.wrap {
  display: flex;
  margin-top: 3rem;
  width: 100%;
}

.menuWrap {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.gridWrap {
  display: grid;
  margin: 0 auto;
}

.tokensGrid {
  display: grid;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  justify-items: center;
  align-items: center;
}

.whiteTxt {
  color: #fff;
  width: 100%;
  margin-top: 2rem;
  text-align: center;
}

.shareBtn {
  position: absolute;
  right: 0;
  top: 120px;
  width: 170px;
  padding: 0.8rem;
  border-radius: 5px;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  color: rgb(230, 228, 228);
  background-color: rgb(236, 67, 67);
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 5px 0 rgb(236, 67, 67) inset, 0 0 15px 2px rgb(236, 67, 67);
  }
}
.sharableAddrBtn {
  position: absolute;
  right: 0;
  width: 170px;
  top: 170px;
  padding: 0.8rem;
  border-radius: 5px;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  color: rgb(230, 228, 228);
  background-color: #00d7c1c9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 15px 2px #00d7c3;
  }
}

.addressBtn {
  position: absolute;
  right: 0;
  top: 120px;
  padding: 0.8rem;
  border-radius: 5px;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  color: rgb(230, 228, 228);
  background-color: #00d7c1c9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 15px 2px #00d7c3;
  }
}

.publicTag {
  background-color: rgb(236, 67, 67);
  font-size: 1rem;
  padding: 0.3rem;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  letter-spacing: 0.1rem;
  padding-left: 2rem;
  margin-left: -1rem;
  margin-top: 2rem;
  color: rgb(230, 228, 228);
  min-width: 140px;
  max-width: 140px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.privateTag {
  background-color: #3b9f6f;
  font-size: 1rem;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  letter-spacing: 0.1rem;
  padding: 0.3rem;
  padding-left: 2rem;
  margin-left: -1rem;
  margin-top: 2rem;
  color: rgb(212, 212, 212);
  min-width: 140px;
  max-width: 140px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.userTag {
  background-color: #00d7c1c9;
  font-size: 1rem;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.74);
  letter-spacing: 0.1rem;
  padding: 0.3rem;
  padding-left: 2rem;
  margin-left: -1rem;
  margin-top: 2rem;
  color: rgb(212, 212, 212);
  min-width: 140px;
  max-width: 140px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.centerViewAbsolute {
  color: var(--quaternary-color);
  top: 50%;
  left: 50%;
  margin-top: 2rem;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .menuWrap {
    width: 95%;
    padding: 1.5rem;
  }
  .gridWrap {
    margin-left: 3rem;
  }
  .tokensGrid {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
    margin-top: 7rem;
    gap: 2rem;
    width: 100%;
    z-index: 99;
  }
  .shareBtn {
    top: 160px;
    margin-right: -1rem;
    transform: scale(0.7);
  }
  .addressBtn {
    top: 160px;
    margin-right: -0.5rem;
    transform: scale(0.75);
  }
  .whiteTxt {
    font-size: 0.8rem;
    position: absolute;
  }
}

.textAbsoluteCenter {
  position: absolute;
  width: 100vw;
  text-align: center;
  color: white;
}

@media (min-width: 900px) {
  .tokensGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .shareBtn {
    top: 60px;
    transform: scale(0.8);
  }
  .addressBtn {
    top: 60px;
    transform: scale(0.8);
  }
  .sharableAddrBtn {
    top: 100px;
    transform: scale(0.8);
  }

  .centerViewAbsolute {
    font-size: 0.8rem;
    margin-top: 5.5rem;
    left: 60%;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1072px) {
  .tokensGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 500px) {
  .wrap {
    width: 20%;
  }
  .sharableAddrBtn {
    top: 120px;
    margin-right: -0.95rem;
    transform: scale(0.7);
  }

  .centerViewAbsolute {
    font-size: 0.5rem;
    margin-top: 5rem;
    left: 70%;
  }
}
