.container {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 2rem;
  padding: 0.5rem;
  margin-left: 1rem;
  margin-top: 1.5rem;
}

.tab {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  font-size: 1.2rem;
  color: var(--secondary-color);
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.plus {
  height: 20px;
  width: 20px;
}

.active {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 10px;
  padding: 13px;
  background-color: var(--tertiary-color);
  color: rgba(255, 255, 255, 0.884);
}

@media screen and (max-width: 1074px) {
  .container {
    display: none;
  }
}
