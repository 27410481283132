.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  margin-bottom: 4rem;
  color: var(--secondary-color);
  
  button {
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 60%;
  margin-left: 12rem;
  margin-top: 5rem;

  h1 {
    font-size: 3.3rem;
  }
}

.uppyPanel {
  width: 410px;
  text-align: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 1em;
  z-index: 999;
}

.label {
  font-size: 1.5rem;
  // color: rgba(255, 255, 255, 0.7);
  color: var(--secondary-color);
  margin-bottom: 1em;
  margin-top: 1em;
}

.subLabel {
  font-size: 1.2rem;
  // color: rgba(255, 255, 255, 0.7);
  color: var(--secondary-color);
  margin-bottom: 1em;
}

.required {
  color: rgba(255, 0, 0, 0.945);
}

.dropArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 2px dashed #3b9f6f;
  border-radius: 5px;
  width: 370px;
  height: 240px;
  padding: 20px;
  margin-bottom: 1em;

  input {
    opacity: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  img {
    transform: scale(0.6);
  }
}

.inputStyle {
  height: 50px;
  border-radius: 10px;
  background: rgba(59, 159, 111, 0.349);
  border: none;
  margin-bottom: 1em;
}

.selectSection {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1em;
  select {
    display: flex;
    cursor: pointer;
    width: 95%;
  }

  img {
    height: 25px;
    width: 25px;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(71%) contrast(73%);

    &:hover {
      cursor: pointer;
      filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
        brightness(91%) contrast(93%);
    }
  }
  input {
    width: 100%;
    margin-bottom: 0px;
  }
}

.selectStyle {
  height: 50px;
  border-radius: 10px;
  background: rgba(59, 159, 111, 0.349);
  border: none;

  .optionStyle {
    background: #07301c;
    color: rgba(255, 255, 255, 0.87);
  }
}

.longInput {
  border-radius: 10px;
  background: rgba(59, 159, 111, 0.349);
  border: none;
  resize: none;
  margin-bottom: 1em;
}

.textSection {
  display: flex;
  flex-direction: column;

  input::placeholder {
    font-size: 1.1rem;
    font-weight: bold;
  }
  textarea::placeholder {
    font-size: 1.1rem;
    padding-top: 1rem;
    font-weight: bold;
  }
  input,
  select {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 1rem;
  }
  textarea {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 1rem;
    padding-top: 1rem;
  }
  textarea:focus,
  input:focus {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.bar {
  border-top: 1px solid #215a3f;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nsfw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.gallery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.warning {
  height: 30px;
  width: 30px;
  margin-top: 1.7rem;
}

.warningText {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.3rem;
    // color: rgba(255, 255, 255, 0.7);
    color: var(--secondary-color);
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  .subtitle {
    font-size: 1rem;
    // color: rgba(255, 255, 255, 0.5);
    color: var(--secondary-color);
    font-weight: 200;
  }
}

.nonExplicitTag {
  align-self: flex-end;
  font-size: 0.6rem;
  font-weight: 500;
  color: var(--secondary-color);
  transition: all 1s;
}

.explicitTag {
  align-self: flex-end;
  font-size: 0.6rem;
  font-weight: 500;
  color: #ff3021dc;
  transition: all 1s;
}

.denyTag {
  align-self: flex-end;
  font-size: 0.6rem;
  font-weight: 500;
  color: var(--secondary-color);
  transition: all 1s;
}

.approveTag {
  align-self: flex-end;
  font-size: 0.6rem;
  font-weight: 500;
  color: #1d9e8f;
  transition: all 1s;
}

.toggleExplicitBtn {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 30px;
    background: rgba(128, 128, 128, 0.39);
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.253);
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #ff3021dc;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: #fff;
  }
}

.toggleGalleryBtn {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 30px;
    background: rgba(128, 128, 128, 0.39);
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.253);
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #00d7c3;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: #fff;
  }
}

.createBtn {
  background: #3b9f6fec;
  border-radius: 20px;
  border: none;
  width: 190px;
  height: 90px;
  margin-top: 2rem;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
    border: 3px solid #00d7c3;
  }
}
.inputFeedback {
  color: rgb(235, 54, 54);
  margin-top: -15px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 5px;
}
@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }
  .content {
    margin-left: 6rem;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .uppyPanel {
    width: 325px;
    align-self: center;
  }
  .content {
    margin-left: -1rem;
    padding: 1rem;
    width: 100%;
    margin-top: -7rem;
    transform: scale(0.8);

    h1 {
      font-size: 2rem;
    }
  }
}
