.gallery {
  padding: 1rem;
  columns: 2 100px;
  column-gap: 1em;
}

.header {
  padding: 1rem;
  h1 {
    color: var(--secondary-color);
    font-size: 1.2rem;
  }
}

.galleryTypeSection {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.galleryTypeBtn {
  color: var(--secondary-color);
  background-color: #3b9f6f34;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.selected {
    background-color: #42d890c5;
}
.errMsg {
  width: 100%;
  margin: 0 auto;
  color: red;
  text-align: center;
}
// Small screens
@media (min-width: 640px) {
  .gallery {
    columns: 3 120px;
  }
  .header {
    h1 {
      font-size: 1.5rem;
    }
  }
}

// Medium screens
@media (min-width: 768px) {
  .gallery {
    columns: 3 230px;
  }
  .header {
    h1 {
      font-size: 1.7rem;
    }
  }
}

// Large Screens
@media (min-width: 1024px) {
  .gallery {
    columns: 4 230px;
  }
  .header {
    h1 {
      font-size: 1.9rem;
    }
  }
}

// Extra Large Screens
@media (min-width: 1280px) {
  .gallery {
    columns: 5 230px;
  }
  .header {
    h1 {
      font-size: 2rem;
    }
  }
}
