.container {
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 650px;
  border-radius: 5px;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.055);
  margin: 0 auto;
  .header {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 1.1rem;

    h2 {
      color: rgba(255, 255, 255, 0.89);
      font-size: 1.4rem;
    }
  }
  button {
    text-align: center;
  }
}

.header {
  display: flex;
  margin-top: 1rem;
}

.xmark {
  width: 28px;
  height: 28px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(71%) contrast(73%);

  &:hover {
    cursor: pointer;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}

.fire {
  height: 110px;
  width: 110px;
  margin: 0 auto;
  margin-top: 3rem;
  filter: invert(57%) sepia(52%) saturate(3106%) hue-rotate(344deg)
    brightness(100%) contrast(102%);

  &:hover {
    cursor: pointer;
    filter: invert(51%) sepia(48%) saturate(4948%) hue-rotate(339deg)
      brightness(99%) contrast(103%);
  }
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  h3 {
    padding: 0;
    margin: 0;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  p {
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.568);
    margin-bottom: 0.5rem;
  }

  span {
    color: #3587d2;
    text-decoration: underline;
    font-style: italic;
    font-weight: 800;
  }
}

.warnSection {
  display: flex;
  flex-direction: column;
  height: 90px;
  width: 80%;
  background-color: rgba(221, 63, 35, 0.075);
  border-left: 3px solid rgb(241, 125, 30);
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 1.5rem;

  p {
    color: rgb(241, 125, 30);
    font-size: 0.8rem;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.warnHeader {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  h4 {
    padding: 0;
    margin: 0;
    color: rgb(197, 18, 18);
    letter-spacing: 0.1em;
  }
}

.warnLogo {
  height: 22px;
  width: 22px;
  filter: invert(58%) sepia(87%) saturate(1342%) hue-rotate(331deg)
    brightness(98%) contrast(94%);
}

.btnSection {
  display: flex;
  gap: 1rem;
  margin: 0 auto;
}

.burnBtn {
  background: rgb(163, 16, 16);
  border-radius: 5px;
  border: none;
  align-self: center;
  width: 150px;
  height: 33px;
  margin-top: 2.2rem;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 0 #df1919 inset, 0 0 5px 2px #df1010;
    border: 1px solid #ff6d19;
  }
}

.cancelBtn {
  background: rgb(100, 98, 98);
  border-radius: 5px;
  border: none;
  align-self: center;
  width: 150px;
  height: 33px;
  margin-top: 2.2rem;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 0 #00d7c141 inset, 0 0 5px 2px #00d7c13a;
    border: 1px solid #00d7c134;
  }
}

// Success view

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 80%;

  h2 {
    color: #fff;
  }

  span {
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 1rem;
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.568);
    font-weight: 500;
  }
}

.txid {
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
  word-break: break-all;
  width: 80%;
  color: #00d7c3;
}

.check {
  height: 100px;
  width: 100px;
  filter: invert(75%) sepia(34%) saturate(7229%) hue-rotate(131deg)
    brightness(102%) contrast(101%);
}

@media only screen and (max-width: 768px) {
  .container {
    width: 390px;
    transform: scale(0.9);
  }
}