.container {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  align-content: center;
  background-color: rgb(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);
  border-radius: 6px;
  margin: 0 auto;
  width: 600px;
  min-width: 600px;
  min-height: 300px;

  h2 {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    color: rgb(238, 234, 234);
    padding-top: 1rem;
    margin: 0;
  }

  p {
    width: 85%;
    margin: 0 auto;
    color: rgb(236, 235, 235);
    padding: 0.5rem;
    font-weight: 300;
    font-size: 1rem;
    text-align: justify;
  }

  input {
    height: 40px;
    border-radius: 10px;
    background: rgba(59, 159, 111, 0.349);
    color: #fff;
    border: none;
    outline: none;
    width: 75%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.balanceSection {
  display: flex;
  // flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 1rem;
  width: 90%;
  margin-bottom: 1.6rem;

  img {
    width: 50px;
    height: 50px;
    filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
      brightness(72%) contrast(72%);
  }

  h3 {
    background-color: #3b9f6f;
    font-size: 1rem;
    font-weight: 400;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.932);
    letter-spacing: 0.22rem;
    padding: 0.3rem;
    padding-left: 2rem;
    margin-left: -2rem;
    margin-top: 0.9rem;
    color: rgb(212, 212, 212);
    min-width: 140px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  button {
    margin: 0 0 0 auto;
    margin-top: 0.7rem;
    background: #3b9f6fec;
    border-radius: 10px;
    border: none;
    width: 120px;
    height: 40px;
    text-align: center;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 10px 1px #00d7c3;
    }
  }
}

.bar {
  display: block;
  border: 1px solid rgba(175, 175, 175, 0.199);
  opacity: 30%;
  width: 90%;
  height: 0px;
  margin-bottom: 1rem;
}

.verificationSection {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  align-content: center;

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    color: rgb(238, 234, 234);
    margin: 0;
  }

  span {
    width: 85%;
    padding: 0.5rem;
    margin: 0;
    font-size: 1rem;
    color: rgb(236, 235, 235);
    font-weight: 300;
    text-align: justify;
  }
}

.badgeSection {
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.emailBadge {
  width: 50px;
  height: 50px;
  filter: invert(14%) sepia(81%) saturate(5783%) hue-rotate(357deg)
    brightness(81%) contrast(92%);
  opacity: 0.3;
}

.emailBadgeVerified {
  width: 50px;
  height: 50px;
  filter: invert(14%) sepia(81%) saturate(5783%) hue-rotate(357deg)
    brightness(81%) contrast(92%);
  opacity: 1;
}
.telegramBadge {
  width: 50px;
  height: 50px;
  filter: invert(31%) sepia(67%) saturate(4921%) hue-rotate(182deg)
    brightness(103%) contrast(100%);
  opacity: 0.2;
}
.telegramBadgeVerified {
  width: 50px;
  height: 50px;
  filter: invert(31%) sepia(67%) saturate(4921%) hue-rotate(182deg)
    brightness(103%) contrast(100%);
  opacity: 1;
}

.emailSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
  width: 150px;
  height: 135px;
  background-color: rgb(19, 18, 18);
  border: 1px solid rgba(128, 128, 128, 0.185);
  border-radius: 6px;
  justify-content: center;

  span {
    text-align: center;
    color: #fff;
  }
}

.telegramSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
  width: 150px;
  height: 135px;
  background-color: rgb(19, 18, 18);
  border: 1px solid rgba(128, 128, 128, 0.185);
  border-radius: 6px;
  justify-content: center;

  span {
    text-align: center;
    color: #fff;
  }
}

.verifyBtn {
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 0.2rem;
  border-radius: 3px;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.btnArea {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  text-align: center;
  margin-bottom: 1.5rem;
  width: 120px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

.submitBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  text-align: center;
  margin-bottom: 1.5rem;
  width: 140px;
  height: 33px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #2bf1b6;
  }
}

.joinBtn {
  // padding: 0.5rem;
  width: 100%;
  background-color: #3b9f6f;
  text-align: center;
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 10px 2px #00d7c3;
  }
}

.iconsSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 1rem;
  gap: 1rem;
}

.bot {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.pulse {
  width: 75px;
  height: 75px;
  background: #fff;
  border-radius: 50%;
  align-self: start;
  animation: animate-pulse 5s linear infinite;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(42, 171, 238, 0.7), 0 0 0 0 rgba(42, 171, 238, 0.7);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 109, 74, 0),
      0 0 0 0 rgba(42, 171, 238, 0.7);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 109, 74, 0),
      0 0 0 10px rgba(255, 109, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 10px rgba(255, 109, 74, 0);
  }
}

// Small screens
@media (max-width: 475px) {
  .container {
    min-width: 370px;
    width: 370px;
    transform: scale(0.9);
  }

  .balanceSection {
    flex-direction: column;
    gap: 0.9rem;
    img {
      width: 70px;
      height: 70px;
    }

    button {
      margin: 0 auto;
    }

    h3 {
      background-color: #3b9f6f;
      font-size: 1.1rem;
      font-weight: 400;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.932);
      letter-spacing: 0.22rem;
      padding: 0.5rem;
      color: rgb(212, 212, 212);
      border-radius: 10px;
      margin: 0 auto;
    }
  }
}
