.container {
  display: flex;
  flex-direction: column;
  min-height: 460px;
  width: 400px;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.055);
  margin: 0 auto;
  margin-bottom: 3rem;
  border-radius: 5px;

  span {
    color: #fff;
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 0.8rem;
  }
  button {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.header {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 1.1rem;

  h2 {
    color: rgba(255, 255, 255, 0.89);
    font-size: 1.4rem;
  }
}

.xmark {
  width: 28px;
  height: 28px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(71%) contrast(73%);

  &:hover {
    cursor: pointer;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}

.inputArea {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
  position: relative;

  input::placeholder {
    padding-left: 1rem;
  }
}

.qrcode {
  position: absolute;
  right: 38px;
  height: 30px;
  width: 30px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(81%) contrast(83%);

  &:hover {
    cursor: pointer;
    filter: invert(75%) sepia(34%) saturate(7229%) hue-rotate(131deg)
      brightness(102%) contrast(101%);
  }
}

.inputStyle {
  height: 30px;
  width: 327px;
  border-radius: 5px;
  background: rgba(59, 159, 111, 0.2);
  border: none;
  margin-left: 2rem;
  color: #fff;
}

.addressError {
  display: inline-block;
  color: rgb(255, 0, 0);
  font-size: 0.73rem;
  text-align: center;
  margin-top: 0.3rem;
}

.middleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
}

.nftFile {
  padding: 0;
  margin: 0 auto;
  width: 35%;
  border-radius: 5px;
  margin-top: 1.5rem;
  align-self: center;
}

.sendBtn {
  background: #3b9f6fec;
  border-radius: 5px;
  border: none;
  align-self: center;
  width: 327px;
  height: 30px;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 0 #00d7c3 inset, 0 0 5px 2px #00d7c3;
    border: 1px solid #00d7c3;
  }
}

.spinner {
  position: absolute;
  top: 100px;
  left: -50px;
  
}

@media only screen and (max-width: 768px) {
  .container {
    transform: scale(0.8);
  }
}

// Success view

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 80%;

  h2 {
    color: #fff;
  }

  span {
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 1rem;
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.568);
    font-weight: 500;
  }
}

.txid {
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
  word-break: break-all;
  width: 80%;
  color: #00d7c3;
}

.check {
  height: 120px;
  width: 120px;
  margin-top: 1.5rem;
  filter: invert(75%) sepia(34%) saturate(7229%) hue-rotate(131deg)
    brightness(102%) contrast(101%);
}


.warnSection {
  display: flex;
  flex-direction: column;
  height: 90px;
  width: 80%;
  background-color: rgba(221, 63, 35, 0.075);
  border-left: 3px solid rgb(241, 125, 30);
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 1.5rem;

  p {
    color: rgb(241, 125, 30);
    font-size: 0.8rem;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.warnHeader {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  h4 {
    padding: 0;
    margin: 0;
    color: rgb(197, 18, 18);
    letter-spacing: 0.1em;
  }
}

.warnLogo {
  height: 22px;
  width: 22px;
  filter: invert(58%) sepia(87%) saturate(1342%) hue-rotate(331deg)
    brightness(98%) contrast(94%);
}