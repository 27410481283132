.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  h1 {
    color: var(--secondary-color);
    padding: 1rem;
  }
}

.faqLinkSection {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-top: 2rem;
  gap: 2rem;
  background-color: rgba(128, 128, 128, 0.11);
  border-radius: 1rem;
  color: var(--secondary-color);
  width: 88%;

  button {
    padding: 0.5rem;
    background-color: #3b9f6f;
    text-align: center;
    text-decoration: none;
    border-radius: 0.5rem;
    padding: 1rem;
    color: rgb(255, 255, 255);
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 10px 2px #00d7c3;
    }
  }
}

.linkStyle {
  text-decoration: none;
}

.telegramBotSection {
  display: flex;
  width: 90%;
  padding: 1rem;
  background-color: rgba(128, 128, 128, 0.11);
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.iconsSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  padding: 1rem;
}

.infoSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  color: var(--secondary-color);
  padding: 1rem;

  p {
    line-height: 1.5rem;
  }

  h2 {
    font-family: "Russo One", sans-serif;
    letter-spacing: 0.2rem;
    font-weight: lighter;
    margin: 0;
    padding: 0;
  }
}

.joinBtn {
  padding: 0.5rem;
  width: 50%;
  background-color: #3b9f6f;
  text-align: center;
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 10px 2px #00d7c3;
  }
}

.bot {
  width: 95px;
  height: 95px;
  border-radius: 50%;
}

.pulse {
  width: 95px;
  height: 95px;
  background: #fff;
  border-radius: 50%;
  align-self: start;
  animation: animate-pulse 5s linear infinite;
  cursor: pointer;
}

.about {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(245, 57, 57);
  color: #fff;
  font-family: "Russo One", sans-serif;
  padding: 0.6rem;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  img {
    height: 30px;
    width: 30px;
    filter: invert(100%) sepia(96%) saturate(16%) hue-rotate(284deg)
      brightness(103%) contrast(106%);
  }
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(42, 171, 238, 0.7), 0 0 0 0 rgba(42, 171, 238, 0.7);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 109, 74, 0),
      0 0 0 0 rgba(42, 171, 238, 0.7);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 109, 74, 0),
      0 0 0 10px rgba(255, 109, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 10px rgba(255, 109, 74, 0);
  }
}

// Small screens
@media screen and (max-width: 475px) {
  .container {
    h1 {
      width: 100%;
      text-align: center;
    }
  }

  .faqLinkSection {
    flex-direction: column;
    gap: 1rem;
  }
  .telegramBotSection {
    flex-direction: column;
    align-items: center;
  }
  .iconsSection {
    width: 60%;
    gap: 1rem;
  }
  .infoSection {
    width: 95%;

    h2 {
      font-size: 1.2rem;
    }
  }
  .pulse {
    height: 60px;
    width: 60px;
  }
  .bot {
    height: 60px;
    width: 60px;
    margin-top: 1.7rem;
  }

  .joinBtn {
    width: 85%;
  }
}

// Medium screens
@media (min-width: 768px) {
  .pulse {
    align-self: center;
    margin-top: -6rem;
  }

  .joinBtn {
    width: 80%;
  }

  .infoSection {
    p {
      text-align: justify;
    }
  }
}

// Large Screens
@media (min-width: 1024px) {
  .joinBtn {
    width: 50%;
  }

  .infoSection {
    p {
      width: 90%;
    }
  }
}
