.container {
  width: 500px;
  text-align: center;
  padding: 1.5rem;
  border-radius: 0.2rem;
  background-color: rgba(10, 10, 10, 1);
  color: #fff;

  h1 {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    color: rgb(238, 234, 234);
    padding: 0;
    margin: 0;
  }

  h3 {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    color: rgb(238, 234, 234);
    font-weight: 100;
  }

  p {
    width: 79%;
    margin: 0 auto;
    color: rgb(190, 188, 188);
    padding: 1rem;
    font-weight: 200;
    margin-bottom: 1rem;
  }

  hr {
    border: none;
    border-top: 0.1px solid #00d7c125;
    width: 90%;
  }
}

.imgSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.384);
}

.imgSection {
  background-color: rgba(77, 76, 76, 0.048);
  width: 35%;
  padding: 1rem;
  border-radius: 0.2rem;
  cursor: pointer;

  h2 {
    font-weight: 100;
    padding: 0.7rem;
    margin: 0;
    transition: transform 0.5s ease-in-out;
  }

  img {
    width: 80px;
    height: 80px;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(61%) contrast(73%);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    color: #fff;
    h2 {
      transform: scale(1.1);
      font-weight: 300;
    }
    img {
      filter: invert(62%) sepia(41%) saturate(1076%) hue-rotate(126deg)
        brightness(96%) contrast(101%);
      transform: scale(1.15);
    }
  }
}

.inputSection {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  label {
    align-self: flex-start;
    margin-left: 0.6rem;
    margin-bottom: 0.3rem;
    font-weight: 300;
    font-size: 0.9rem;
  }
}

.inputStyle {
  height: 40px;
  border-radius: 10px;
  background: rgba(59, 159, 111, 0.349);
  color: #fff;
  border: none;
  outline: none;
  width: 96%;
  margin-bottom: 1rem;
}

.btnSection {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1rem;
  gap: 1rem;
}

.submitBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #3b9f6f;
  }
}

.backBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #00d7c1;
  }
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

.effect {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      rgb(12, 12, 12),
      rgb(11, 11, 12) 50%,
      rgb(17, 17, 17)
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #00d7c3, #05eed7);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  animation-play-state: paused;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: running;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.effect2 {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      rgb(12, 12, 12),
      rgb(11, 11, 12) 50%,
      rgb(17, 17, 17)
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #00d7c3, #05eed7);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  animation-play-state: paused;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: running;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@media (max-width: 768px) {
  .container {
    width: 290px;

    p {
      width: 90%;
    }
  }

  .imgSection {
    
    img {
      width: 60px;
      height: 60px;
    }
    
  }

}
