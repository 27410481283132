.container {
  display: flex;
  justify-content: space-around;
  padding: 3rem;
  width: 90%;
  color: var(--secondary-color);
}

.infoSection {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 3rem;
  gap: 1rem;
  width: 60%;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 3.5rem;
    padding-left: 0.6rem;
  }

  p {
    margin: 0;
    padding: 0;
    width: 350px;
    padding-left: 0.6rem;
    font-weight: 300;
  }
}

.infoSectionBottom {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 30%;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 10px;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    opacity: 0.8;

    h3 {
      font-weight: 600;
      font-size: 0.9rem;
    }

    p {
      font-weight: 300;
      padding: 0;
      margin: 0;
      line-break: strict;
      font-size: 0.9rem;
      width: 100%;
    }
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 1rem;
  border-radius: 20px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  opacity: 0.8;

  h2 {
    padding: 0;
    margin: 0;
    font-size: 2.2rem;
  }

  p {
    font-weight: 500;
  }

  input {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 50px;
    font-weight: bold;
    border: 1px solid #3b9f6f;
    width: 100%;
  }

  input.error {
    border-color: red;
  }

  .inputFeedback {
    color: rgb(235, 54, 54);
    margin-top: -2px;
    font-size: 12px;
    margin-left: 0.5rem;
  }
}

.formInputTop {
  display: flex;
  gap: 3rem;
  padding: 0.3rem 0.6rem;
}

.emailInput {
  width: 97%;
  padding: 0.6rem;

  input {
    width: 94%;
  }
}

.longInput {
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-top: 1rem;
  border-radius: 10px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 1px solid #3b9f6f;
  resize: none;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 0.3rem;
}

.submitBtn {
  margin: 0 auto;
  margin-top: 0.6rem;
  background: #3b9f6fec;
  border-radius: 10px;
  border: none;
  width: 95%;
  height: 40px;
  text-align: center;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:enabled:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 10px 1px #00d7c3;
  }
  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #646464;
  }
}

.telegramSection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 auto;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  a {
    font-weight: 300;
    text-decoration: none;
    color: var(--primary-color);
  }
}

@media screen and (max-width: 475px) {
  .container {
    flex-direction: column;
    padding: 1rem;
  }

  .infoSectionBottom {
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    div {
      width: 90%;
      margin: 0 auto;
    }
  }

  .formSection {
    width: 90%;
  }
}
