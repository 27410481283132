.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1.8rem;
  width: 40%;
  padding: 0.6rem;
  
  span {
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 0.8rem;
    opacity: 0.4;
  }
}

.container::after {
  content: "";
  background-color: #3b9f6f75;
}

.proBtn {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.4rem;
}

.credits {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  gap: 0.4rem;

  &:hover {
    span {
      opacity: 0.7;
    }
  }
  span{
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }
}

.creditLogo {
  height: 27px;
  width: 27px;
  padding: 0.3rem;
  cursor: pointer;
  filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
    brightness(72%) contrast(72%);

  &:hover {
    filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
      brightness(92%) contrast(92%);
  }
}

.user {
  height: 27px;
  width: 27px;
  padding: 0.3rem;
  cursor: pointer;
  filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
    brightness(72%) contrast(72%);

  &:hover {
    filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
      brightness(92%) contrast(92%);
  }
}
.power {
  height: 20px;
  width: 20px;
  cursor: pointer;
  filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
    brightness(72%) contrast(72%);

  &:hover {
    filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
      brightness(92%) contrast(92%);
  }
}

.themeBtn {
  color: #3b9f6fd7;
  font-size: 1.3rem;
  cursor: pointer;
}

.toggleBtn {
  margin-bottom: 1rem;
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    // cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 30px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    opacity: 0.2;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #215a3f79;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 1024px) {
  .container {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1074px) {
  .container {
    display: none;
  }
}
