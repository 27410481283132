:root {
  --primary-color: rgb(0, 0, 0);
  --secondary-color: rgb(255, 255, 255);
  --tertiary-color: #3b9f6f34;
  --quaternary-color: rgba(255, 255, 255, 0.4);
  --mode: dark;
}
body {
  margin: 0;
  /* background: url("./components/assets/Hexagon.svg")no-repeat center center
  fixed; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  background-color: var(--primary-color);
  /* background-blend-mode: darken; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all .5s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
