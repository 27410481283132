.container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
}

.insideSection {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-left: 1.2rem;

  h3 {
    padding: 0;
    margin: 0;
    color: var(--secondary-color);
    word-break: keep-all;
    margin-right: 1rem;
  }
}
.insideSectionTab {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
}
.insideTabs {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-left: 1.2rem;

  .tab {
    color: var(--secondary-color);
    font-weight: 600;
    padding: 13px;
    transition: all 0.5s;
  }
  .active {
    color: rgba(255, 255, 255, 0.89);
    border-radius: 10px;
    padding: 13px;
    background-color: var(--tertiary-color);
    font-weight: 600;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
    }
  }
  .quantity {
    color: var(--secondary-color);
    font-weight: 100;
  }
}
.chevron {
  height: 25px;
  width: 25px;
  filter: invert(44%) sepia(90%) saturate(304%) hue-rotate(99deg)
    brightness(102%) contrast(82%);
  transform: rotate(-0deg);
  transition: transform 700ms ease;
}

.chevronDown {
  height: 25px;
  width: 25px;
  filter: invert(44%) sepia(90%) saturate(304%) hue-rotate(99deg)
    brightness(102%) contrast(82%);
  transform: rotate(180deg);
  transition: transform 700ms ease;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0.1rem;
    z-index: 999;
    background: var(--primary-color);
  }

  .chevron {
    width: 18px;
    height: 18px;
  }

  .chevronDown {
    width: 18px;
    height: 18px;
  }

  .insideSection {
    width: 100%;
    margin-left: 0;

    h3 {
      padding: 1rem;
      font-size: 0.7rem;
      margin-left: -1rem;
      margin-right: 0.3em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .insideSectionTab{
    padding-right: 1em;

  }

  .insideTabs {
    width: 100%;
    margin-left: 0;

    .active {
      font-size: 0.7rem;
      padding: 9px;
    }
  }
}
