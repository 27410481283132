.container {
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 1.5em;
}

.section {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  cursor: pointer;
  width: 70%;

  h2 {
    color: var(--secondary-color);
    margin-right: 1rem;
  }
}

.chevron {
  height: 25px;
  width: 25px;
  filter: invert(44%) sepia(90%) saturate(304%) hue-rotate(99deg)
    brightness(102%) contrast(82%);
  transform: rotate(-0deg);
  transition: transform 700ms ease;
}

.chevronDown {
  height: 25px;
  width: 25px;
  filter: invert(44%) sepia(90%) saturate(304%) hue-rotate(99deg)
    brightness(102%) contrast(82%);
  transform: rotate(180deg);
  transition: transform 700ms ease;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 255, 255, 0.411);
  padding: 1em;
}

.hamburger {
  height: 40px;
  width: 50px;
  margin-top: 2rem;
  cursor: pointer;
  filter: invert(44%) sepia(90%) saturate(304%) hue-rotate(99deg)
    brightness(102%) contrast(82%);
}

@media screen and (max-width: 768px) {
  .container {
    width: 45%;
    margin-left: -1rem;
    padding: 2rem;
    position: absolute; 
  }
  .hamburger {
    margin-left: -1rem;
    height: 25px;
    width: 30px;
  }
  .chevron {
    width: 18px;
    height: 18px;
  }
  .chevronDown {
    width: 18px;
    height: 18px;
  }
  .section {
    width: 100%;
    h2 {
      font-size: 0.8rem;
      margin-right: 0.3em;
    }
  }
}
