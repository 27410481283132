.container {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  align-content: center;
  background-color: rgb(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);
  margin: 0 auto;
  padding: 4rem;
  width: 450px;
  max-height: 450px;
  overflow-y: auto;
  position: relative;
  button {
    text-align: center;
  }
}

.proBtn {
  display: none;
}

.emailSection {
  background-color: rgba(19, 19, 19, 0.863);
  border: 1px solid rgb(19, 19, 19);
  padding: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  width: 90%;
}

.passwordSection {
  background-color: rgba(19, 19, 19, 0.863);
  border: 1px solid rgb(19, 19, 19);
  padding: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  width: 90%;
  margin-top: 0.7rem;
}

.passwrdSection {
  background-color: rgba(19, 19, 19, 0.863);
  border: 1px solid rgb(19, 19, 19);
  padding: 1rem;
  margin-top: 1rem;
  width: 90%;
}

.warnSection {
  display: flex;
  flex-direction: column;
  height: 90px;
  width: 99%;
  background-color: rgba(221, 63, 35, 0.075);
  border-left: 3px solid rgb(241, 125, 30);
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 1.5rem;

  p {
    color: rgb(236, 129, 58);
    font-size: 0.8rem;
    text-align: center;
    width: 70%;
    padding: 0;
    margin: 0 auto;
  }
}

.warnHeader {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  h4 {
    padding: 0;
    margin: 0;
    color: rgb(236, 129, 58);
    letter-spacing: 0.1em;
  }
}

.warnLogo {
  height: 22px;
  width: 22px;
  filter: invert(58%) sepia(87%) saturate(1342%) hue-rotate(331deg)
    brightness(98%) contrast(94%);
}

.formInfo {
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 410px;
  }

  label,
  input {
    color: #fff;
  }

  label {
    margin-bottom: 5px;
    height: 22px;
  }
  button{
    margin-bottom: 5px;

  }
  input {
    background-color: #3b9f6f1f;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 50px;
    max-width: 90%;
    border: 1px solid #3b9f6f;
  }

  input.error {
    border-color: red;
  }

  .inputFeedback {
    color: rgb(235, 54, 54);
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  button {
    padding: 10px 15px;
    align-self: center;
    width: 45%;
    background-color: #3b9f6f;
    color: white;
    border: 1px solid rgb(70, 153, 179);
    transition: ease-in-out background-color 250ms, ease-in-out color 250ms;
  }

  button:hover {
    cursor: pointer;
    background-color: white;
    color: rgb(70, 153, 179);
  }
}

.closeBtn {
  position: absolute;
  height: 35px;
  width: 35px;
  top: 12px;
  left: 10px;
  filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
    brightness(72%) contrast(72%);

  &:hover {
    cursor: pointer;
    filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
      brightness(92%) contrast(92%);
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 230px;
  }
  .formInfo {
    form {
      width: 210px;
    }
    button {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1074px) {
  .proBtn {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 0.4rem;

    span {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 600;
      font-size: 0.8rem;
      opacity: 0.2;
    }
  }

  .toggleBtn {
    margin-bottom: 1rem;
    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      // cursor: pointer;
      text-indent: -9999px;
      width: 45px;
      height: 30px;
      background: grey;
      display: block;
      border-radius: 100px;
      position: relative;
      opacity: 0.2;
    }

    label:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: #215a3f79;
    }

    input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }
}
