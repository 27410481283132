.container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  color: var(--secondary-color);

  h1 {
    font-size: 3rem;
  }
}

.bar {
  border-top: 1px solid #263026e7;
}

.textSection {
  padding: 0.4rem;
  width: 70%;

  p {
    text-justify: inter-word;
  }
}
