/* reset */
button,
h3 {
  all: unset;
}

.walletLink {
  color: var(--secondary-color);
}

.AccordionRoot {
  border-radius: 6px;
  width: 700px;
  background-color: var(--primary-color);
  box-shadow: 0 2px 10px rgb(70, 70, 70);
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
  cursor: pointer;
}

.AccordionItem:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 2px var(--mauve12);
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  color: var(--secondary-color);
  box-shadow: 0 1px 0 rgba(107, 106, 106, 0.452);
  background-color: var(--primary-color);
}

.AccordionTrigger:hover {
  background-color: rgba(223, 223, 223, 0.623);
}

.AccordionContent {
  overflow: hidden;
  font-size: 15px;
  color: var(--secondary-color);
  background-color: var(--tertiary-color);
}
.AccordionContent[data-state="open"] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state="closed"] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 15px 20px;
}

.AccordionChevron {
  color: var(--violet10);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state="open"] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@media screen and (max-width: 475px) {
  .AccordionRoot {
    width: 340px;
  }
}
