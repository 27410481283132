.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;
  color: #fff;
  text-align: center;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);

  h3 {
    font-size: 1.4rem;
    font-weight: 400;
    color: rgb(238, 234, 234);
    padding: 0;
    margin: 0;
    margin-bottom: 0.7rem;
  }

  p {
    color: rgb(190, 188, 188);
    font-weight: 200;
    margin-bottom: 1rem;
    padding: 0;
    margin: 0;
    margin-bottom: 1.2rem;
    line-height: 1.3;
    width: 80%;
  }

  hr {
    border: none;
    border-top: 0.1px solid #00d7c125;
    width: 100%;
  }

  strong {
    color: #3587d2;
  }
}

.link {
  padding: 1rem;
  font-size: 1.1rem;
  color: rgb(230, 230, 230);
  font-weight: 300;

  a {
    color: rgb(160, 122, 204);
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}

.copyBtn {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  filter: invert(57%) sepia(23%) saturate(938%) hue-rotate(99deg)
    brightness(50%) contrast(91%);

  &:hover {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(190deg)
      brightness(103%) contrast(101%);
    cursor: pointer;
  }
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  margin-top: 2rem;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

@media (max-width: 768px) {
  .container {
    width: 280px;
    margin: 0 auto;

    p {
      width: 90%;
    }
  }
  .link {
    font-size: 0.6rem;
    font-weight: 400;
  }
}
