.textStyle {
  color: var(--secondary-color);

  a {
    color: var(--secondary-color);
  }
}

.downloadBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #2bf1b6;
  }
}

.icon {
  height: 110px;
  width: 110px;
  object-fit: cover;
}
