.container {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  color: var(--secondary-color);

  h1 {
    margin-bottom: 4rem;
  }
}
