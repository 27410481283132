.container {
  margin-right: 4.7rem;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  width: 65%;
}

.themeBtn {
  color: #3b9f6fd7;
  font-size: 1.3rem;
}

.creditSection {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 0.3rem;
  flex-direction: row-reverse;
  cursor: pointer;

  strong {
    font-size: 0.9rem;
    color: var(--secondary-color);
  }
}

.creditsBtn {
  width: 23px;
  height: 23px;
  margin-top: -3px;
  cursor: pointer;
  filter: invert(49%) sepia(77%) saturate(310%) hue-rotate(99deg)
    brightness(72%) contrast(72%);
}

@media screen and (min-width: 1074px) {
  .container {
    display: none;
  }
}
