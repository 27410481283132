.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding: 0.5rem;
  text-align: center;

  h1 {
    color: var(--secondary-color);
    font-family: "Russo One", sans-serif;
    letter-spacing: 0.4rem;
  }

  p {
    color: var(--secondary-color);
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
}

.planSection {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 3rem;
  width: 100%;
  gap: 2.5rem;
  //   background-color: rgba(255, 0, 0, 0.411);
}

.popular {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  background-color: #f8f8f8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 220px;
  padding: 1.5rem;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(53, 53, 53);
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .price {
    font-size: 3rem;
  }

  &:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
    cursor: pointer;
  }
}

.best {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 220px;
  padding: 1.5rem;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(53, 53, 53);
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .price {
    font-size: 3rem;
  }

  .regularPrice {
    text-decoration: line-through;
    font-size: 1.1rem;
    font-weight: 300;
    color: rgba(51, 51, 51, 0.74);
  }

  .discount {
    font-size: 1rem;
    color: rgb(15, 160, 153);
  }

  &:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
    cursor: pointer;
  }
}

.enterprise {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 220px;
  min-height: 250px;
  padding: 1.5rem;
  gap: 1rem;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(53, 53, 53);
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
    font-size: 1.1rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }

  .price {
    font-size: 3rem;
  }

  .newTag {
    background-color: #00d7c1;
    color: #000;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 40%;
    margin-top: -32px;
  }

  &:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
    cursor: pointer;
  }
}

.buyBtn {
  background: #3b9f6fec;
  border-radius: 10px;
  border: none;
  width: 150px;
  height: 50px;
  text-align: center;
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 10px 1px #00d7c3;
  }
}

.link {
  text-decoration: none;
}

.btn {
  display: none;
}

@media screen and (max-width: 475px) {
  .planSection {
    flex-direction: column;
  }
}
