.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 100vh;

  form {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  label,
  input {
    display: block;
    width: 100%;
    color: #fff;
  }

  label {
    margin-bottom: 5px;
    height: 22px;
  }

  input {
    background-color: #3b9f6f1f;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #3b9f6f;
  }

  input.error {
    border-color: red;
  }

  .inputFeedback {
    color: rgb(235, 54, 54);
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .btnContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.btn {
  padding: 10px 15px;
  background-color: #3b9f6f;
  color: white;
  border: 1px solid rgb(70, 153, 179);
  transition: ease-in-out background-color 250ms, ease-in-out color 250ms;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: rgb(70, 153, 179);
  }
}

.copyright {
  color: rgba(255, 255, 255, 0.164);
  font-size: 0.7em;
  margin-top: 1.9rem;
}

.emailSection {
  width: 93%;
}

.passwordSection {
  display: flex;
  position: relative;
}

.eyeIcon {
  position: absolute;
  right: 3px;
  top: 5px;
  height: 25px;
  width: 38px;
  cursor: pointer;
  filter: invert(53%) sepia(16%) saturate(1378%) hue-rotate(99deg)
    brightness(50%) contrast(89%);

  &:hover {
    filter: invert(53%) sepia(16%) saturate(1378%) hue-rotate(99deg)
    brightness(80%) contrast(89%);
  }
}

.reset {
  color: rgba(255, 255, 255, 0.39);
  font-size: 0.8em;
  margin-top: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;

  &:hover {
    color: rgba(255, 255, 255, 0.87);
  }
}
