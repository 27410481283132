.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding: 0.5rem;
  text-align: center;

  h1 {
    color: var(--secondary-color);
    font-family: "Russo One", sans-serif;
    letter-spacing: 0.4rem;
    font-size: 1.5rem;
  }

  h2 {
    color: var(--secondary-color);
    font-size: 1.2rem;
  }

  h3 {
    color: gray;
  }

  img {
    width: 350px;
  }
}

.copyright {
  color: var(--secondary-color);
  font-size: 0.8em;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  opacity: 0.4;
}

// Small screens
@media screen and (max-width: 475px) {
  .container {
    h1 {
      font-size: 1rem;
      letter-spacing: 0.2rem;
    }
    h3 {
      font-size: 0.8rem;
    }
    h2 {
      font-size: 0.9rem;
    }
    img {
      width: 220px;
    }
  }

  .copyright {
    font-size: 0.7em;
  }
}
