.container {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 2rem;
  color: #fff;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);

  h3 {
    font-size: 1.4rem;
    font-weight: 400;
    color: rgb(238, 234, 234);
    padding: 0;
    margin: 0;
    margin-bottom: 0.7rem;
  }

  p {
    color: rgb(190, 188, 188);
    font-weight: 200;
    margin-bottom: 1rem;
    padding: 0;
    margin: 0;
    margin-bottom: 0.8rem;
    width: 70%;
  }

  span {
    color: #3587d2;
    text-decoration: underline;
    font-style: italic;
  }

  hr {
    border: none;
    border-top: 0.1px solid #00d7c125;
    width: 100%;
  }
}

.selectArea {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;

  select {
    height: 40px;
    border-radius: 10px;
    margin-left: -1rem;
    background: rgba(59, 159, 111, 0.349);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    width: 75%;
  }
  input {
    height: 40px;
    border-radius: 10px;
    margin-left: -1rem;
    background: rgba(59, 159, 111, 0.349);
    color: #fff;
    border: none;
    outline: none;
    width: 75%;
  }


  option {
    background: #07301c;
    color: rgba(255, 255, 255, 0.87);
  }
}

.editBtn {
  height: 30px;
  width: 30px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(71%) contrast(73%);

  &:hover {
    cursor: pointer;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}

.submitBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #3b9f6f;
  }
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  margin-top: 2rem;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

// Success view

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 80%;

  h2 {
    color: #fff;
  }

  span {
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 1rem;
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.568);
    font-weight: 500;
  }
}

.txid {
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
  word-break: break-all;
  width: 80%;
  color: #00d7c3;
}

.check {
  height: 120px;
  width: 120px;
  margin-top: 1.5rem;
  filter: invert(75%) sepia(34%) saturate(7229%) hue-rotate(131deg)
    brightness(102%) contrast(101%);
}

@media (max-width: 768px) {
  .container {
    width: 290px;
    text-align: center;

    p {
      width: 100%;
    }
  }
  .selectArea {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    select {
      width: 100%;
    }
  }
}
