.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.qrSize {
  width: 300px;
  height: 300px;
  border: 1px solid #3b9f6f;
}
