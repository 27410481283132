.container {
  display: none;
}

@media screen and (max-width: 1074px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: absolute;
    top: 40px;
    left: 10px;
    cursor: pointer;
    padding: 1em;
    gap: 2rem;
    margin-top: 1.5rem;

    .icon {
      height: 18px;
      width: 18px;
      filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
        brightness(91%) contrast(93%);

      &:hover {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(308deg)
          brightness(108%) contrast(101%);
      }
    }

    .active {
      height: 18px;
      width: 18px;
      filter: invert(56%) sepia(78%) saturate(407%) hue-rotate(106deg)
        brightness(151%) contrast(90%);
    }
  }
}
