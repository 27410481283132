.container {
  padding: 1rem;
  height: 1.5rem;
}

.copyright {
  color: var(--secondary-color);
  font-size: 1em;
  text-align: center;
  padding: 0;
  margin: 0;
  opacity: 0.4;
}

@media screen and (max-width: 768px) {
  .copyright {
    font-size: 0.7rem;
  }
}
