.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background-color: rgb(0, 0, 0);
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: 9999;

  h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    color: rgba(255, 255, 255, 0.7);
  }

  h3 {
    padding: 0;
    margin: 0;
    color: #3b9f6f;
  }

  img {
    height: 330px;
    width: 230px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }
}
