.container {
  position: relative;
  img {
    max-width: 100%;
    border-radius: 1rem;
    margin-bottom: 1em;
    display: block;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
  .spinnerContainer {
    width: 350px;
    height: 250px;
    background-color: grey;
    margin: 5px;
  }
}
.likeSection {
  position: absolute;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.3rem;
  right: -7px;
  bottom: 0;
  width: 30%;

  span {
    color: #f1f5f9;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.2em;
  }
}
.likeBtn {
  width: 15px;
  height: 15px;
  cursor: pointer;
  filter: invert(95%) sepia(2%) saturate(1378%) hue-rotate(191deg)
    brightness(103%) contrast(95%);

  &:hover {
    filter: invert(12%) sepia(61%) saturate(5245%) hue-rotate(351deg)
      brightness(108%) contrast(92%);
  }
}
.commentSection {
  position: absolute;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.3rem;
  left: 10px;
  bottom: -2px;
  width: 30%;

  span {
    color: #f1f5f9;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.2em;
  }
}
.commentBtn {
  width: 19px;
  height: 19px;
  cursor: pointer;
  filter: invert(95%) sepia(2%) saturate(1378%) hue-rotate(191deg)
    brightness(103%) contrast(95%);

  &:hover {
    filter: invert(65%) sepia(81%) saturate(7313%) hue-rotate(146deg)
      brightness(94%) contrast(96%);
  }
}

.namesSection {
  position: absolute;
  background-color: #3131311c;
  border-radius: 1rem;
  width: 60%;
  padding: 0.4rem;
  top: 10px;
  left: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  margin-bottom: 0.2em;
  h3 {
    color: #e2e8f0;
    margin: 0;
    margin-left: 0.2rem;
    padding: 0;
    font-weight: 500;
    font-size: 0.7rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.37);
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  }
  &:hover {
    outline: 1px solid #a1a1aa27;
    background-color: #4948483d;
    h3 {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.952);
    }
  }
}

// Small screens
@media (min-width: 640px) {
  .likeSection {
    right: -15px;
  }
}

// Medium screens
@media (min-width: 768px) {
  .likeSection {
    right: -35px;
  }
}

// Large Screens
@media (min-width: 1024px) {
  .likeSection {
    right: -27px;
  }
  .likeBtn {
    height: 18px;
    width: 18px;
  }
  .commentBtn {
    height: 24px;
    width: 24px;
  }
}

// Extra Large Screens
@media (min-width: 1280px) {
  .namesSection {
    h3 {
      font-size: 0.8rem;
    }
  }
  .commentSection {
    // right: -23px;

    span {
      font-size: 1rem;
    }
  }
  .commentBtn {
    height: 24px;
    width: 24px;
  }
  .likeSection {
    right: -23px;

    span {
      font-size: 1rem;
    }
  }
  .likeBtn {
    height: 20px;
    width: 20px;
  }
}

// XXL Screens
@media (min-width: 1536px) {
  .namesSection {
    h3 {
      font-size: 1rem;
    }
  }

  .commentSection {
    // right: -35px;

    span {
      font-size: 1.2rem;
    }
  }
  .commentBtn {
    height: 33px;
    width: 33px;
  }

  .likeSection {
    right: -35px;

    span {
      font-size: 1.2rem;
    }
  }
  .likeBtn {
    height: 26px;
    width: 26px;
  }
}
