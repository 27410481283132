.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  min-height: 405px;
  min-width: 280px;
  max-width: 280px;
  background-color: var(--tertiary-color);
  border-radius: 10px;
  transition: all 0.5s;
}

.file {
  max-height: 315px;
  min-height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.171);
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 285px;
    min-height: 285px;
    min-width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 1.8rem;
  margin-top: 0.5rem;
  gap: 0.3em;

  h3 {
    color: var(--quaternary-color);
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0;
    margin: 0;
  }

  h4 {
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
}

.icons {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 73%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  img {
    height: 25px;
    width: 25px;
    filter: invert(57%) sepia(23%) saturate(938%) hue-rotate(99deg)
      brightness(50%) contrast(91%);

    &:hover {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(190deg)
        brightness(103%) contrast(101%);
      cursor: pointer;
    }
  }
}
.iconsAround {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 73%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  img {
    height: 25px;
    width: 25px;
    filter: invert(57%) sepia(23%) saturate(938%) hue-rotate(99deg)
      brightness(50%) contrast(91%);

    &:hover {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(190deg)
        brightness(103%) contrast(101%);
      cursor: pointer;
    }
  }
}

.updatingText {
  position: absolute;
  color: var(--secondary-color);
  background-color: rgba(255, 255, 255, 0.342);
  border-radius: 1rem;
  margin-top: 15px;
  margin-left: -130px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
