.container {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  background-color: rgb(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: rgba(255, 255, 255, 0.13) 0px 2px 8px 0px;
  border-radius: 6px;
  margin: 0 auto;
  width: 600px;
  min-width: 600px;
  min-height: 300px;

  h2 {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    color: rgb(238, 234, 234);
    padding-top: 0.5rem;
    margin: 0;
  }

  p {
    width: 85%;
    margin: 0 auto;
    color: rgb(236, 235, 235);
    padding: 1rem;
    font-weight: 300;
    font-size: 0.9rem;
    text-align: center;
  }

  .link {
    font-size: 0.9rem;
    text-decoration: underline;
    font-weight: 400;
    color: rgb(236, 235, 235);
    cursor: pointer;
  }
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  text-align: center;
  margin-top: 1.5rem;
  width: 120px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

.addrWrapper {
  display: flex;
  width: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border: 1px solid #215a3fc7;
  align-items: center;

  span {
    color: white;
  }
  img {
    height: 25px;
    filter: invert(75%) sepia(34%) saturate(7229%) hue-rotate(131deg)
      brightness(102%) contrast(101%);
    cursor: pointer;
    margin-left: 1em;
  }
}

.qrCodeWrapper {
  padding: 1em;
  background-color: rgb(233, 232, 232);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  margin-bottom: 1em;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;

  h3 {
    color: rgb(238, 234, 234);
  }
}

.bar {
  border-top: 1px solid #2a2b2aa9;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.toggleQR {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;

  span {
    color: rgb(236, 235, 235);
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 30px;
    background: rgba(128, 128, 128, 0.39);
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 5px;
    width: 22px;
    height: 22px;
    background: rgba(255, 255, 255, 0.253);
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #21f8ffdc;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: #fff;
  }
}

@media screen and (max-width: 475px) {
  .container {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
  }

  .qrCodeWrapper {
    transform: scale(0.7);
  }

  .addrWrapper {
    transform: scale(0.7);
  }

  .paymentInfo {
    transform: scale(0.7);
  }

  .toggleQR {
    // margin-left: 16%;
    gap: 0.4rem;
    flex-direction: column;
    margin-top: -3rem;

    span {
      font-size: 0.8rem;
    }
  }
}
