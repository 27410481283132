.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 650px;
  padding-bottom: 4rem;
  gap: 1.5rem;
  background-color: #070707;
  border: 1px solid rgba(255, 255, 255, 0.048);
  margin: 0 auto;
  position: relative;
  z-index: 9999;

  a {
    color: rgb(141, 111, 175);
    font-size: 0.9rem;
  }
}

.title {
  color: #fff;
  padding: 0;
  margin: 0;
  margin-top: 3.5rem;

  span {
    color: #3587d2;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
  }
}

.nft {
  padding: 0;
  margin: 0;
  width: 30%;
  cursor: pointer;
}

.txidSection {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  span {
    color: rgba(255, 255, 255, 0.568);
    font-weight: 500;
  }
}

.linksContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 30%;
  margin-bottom: 3rem;
}

.link {
  height: 30px;
  width: 30px;
  filter: invert(53%) sepia(16%) saturate(1378%) hue-rotate(99deg)
    brightness(50%) contrast(89%);

  &:hover {
    filter: invert(53%) sepia(16%) saturate(1378%) hue-rotate(99deg)
      brightness(97%) contrast(89%);
    cursor: pointer;
  }
}
.close {
  cursor: pointer;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 15px;
  left: 15px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(51%) contrast(53%);

  &:hover {
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}

@media screen and (max-width: 700px) {
  .container {
    width: 300px;
    a {
      word-break: break-all;
      width: 80%;
      text-align: center;
    }
  }
  .title {
    font-size: 0.9rem;
    text-align: center;
  }
  .close {
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
  }
  .nft {
    width: 50%;
  }
  .linksContainer {
    width: 65%;
  }

  .link {
    height: 25px;
    width: 25px;
  }
}
