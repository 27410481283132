.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  border: 1px solid #3b9f6f25;
  border-radius: 10px;
  padding: 1rem;
  background-color: rgb(248, 245, 245);
  color: #000;
  margin: 0 auto;
}
.logoText {
  color: #3b9f6f;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-family: "Russo One", sans-serif;
}

.tm {
  font-size: 0.5em;
  font-weight: 200;
}

.boldText {
  font-weight: 500;
}
.content {
  border-radius: 10px;
  width: 80%;
  padding: 2rem;
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    height: 300px;
    text-align: justify;
    text-justify: inter-word;
  }
  h3 {
    font-weight: 300;
  }
  a {
    font-weight: 300;
    color: rgb(43, 114, 247);
  }
  a:visited {
    font-weight: 300;
    color: rgb(43, 114, 247);
  }
}

.bottom {
  margin-top: 1rem;
}

.acceptBtn {
  background-color: var(--primary-color);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  width: 180px;
  height: 33px;
  font-size: 0.8rem;
  color: var(--secondary-color);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #3b9f6f;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 70%;
    margin: 0;
    margin-left: 0.7rem;
  }
  .content {
    width: 90%;
    p {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      height: 250px;
      border-radius: 10px;
      padding: 1em;
    }
  }
}
