.container {
  display: flex;
  flex-direction: column;
  width: 1100px;
  height: 520px;
  padding: 2rem;
  color: #fff;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);
  overflow-y: auto;

  hr {
    border: none;
    border-top: 0.1px solid #00d7c125;
    width: 100%;
  }
}

.titleSection {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  h2 {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    color: rgb(238, 234, 234);
    padding: 0;
    margin: 0;
    margin-top: -20px;
  }

  p {
    width: 65%;
    margin: 0 auto;
    color: rgb(190, 188, 188);
    padding: 1rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
}

.formSection {
  display: flex;
  justify-content: space-between;
  //   background-color: rgba(255, 0, 0, 0.432);
}
.uppyBorderPanel{
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
.uppyPanel {
  //   width: 350px;
  text-align: center;
  justify-content: center;
  align-content: center;
  /* z-index: 999; */
}

.box {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  padding: 1em;
  //   background-color: rgba(0, 0, 255, 0.507);
  width: 32%;

  h3 {
    font-weight: 600;
    margin-top: 0.5rem;
  }

  span {
    color: rgb(190, 188, 188);
    font-weight: 300;
    font-size: 0.8em;
    width: 70%;
  }

  input::placeholder {
    font-size: 1.1rem;
    font-weight: bold;
  }
  textarea::placeholder {
    font-size: 1.1rem;
    padding-top: 1rem;
    font-weight: bold;
  }
  input,
  select {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 0.4rem;
  }
  textarea {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 0.9rem;
    font-weight: bold;
    padding-left: 0.4rem;
    padding-top: 0.4rem;
  }
  textarea:focus,
  input:focus {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 0.9rem;
    font-weight: bold;
  }
}

.longInput {
  border-radius: 10px;
  background: rgba(59, 159, 111, 0.349);
  border: none;
  resize: none;
  margin-bottom: 1em;

  textarea {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 0.5rem;
    font-weight: bold;
    padding-left: 0.4rem;
    padding-top: 0.4rem;
  }
  textarea:focus,
  input:focus {
    // color: #fff;
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.closeBtn {
  height: 30px;
  width: 30px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(71%) contrast(73%);

  &:hover {
    cursor: pointer;
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}

.toggleExplicitBtn {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 30px;
    background: rgba(128, 128, 128, 0.39);
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.253);
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #ff3021dc;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: #fff;
  }
}

.submitBtn {
  background: #3b9f6fec;
  border-radius: 5px;
  border: none;
  align-self: flex-end;
  text-align: center;
  width: 28%;
  height: 30px;
  margin-top: auto;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 0 #00d7c3 inset, 0 0 5px 2px #00d7c3;
    border: 1px solid #00d7c3;
  }
}
.inputFeedback {
  color: rgb(235, 54, 54);
  margin-top: -15px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.updateMsgSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;

  img {
    width: 250px;
    height: 250px;
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-align: center;
    color: rgb(238, 234, 234);
    margin-top: 2rem;
  }
}

// Small screens
@media (max-width: 640px) {
  .container {
    width: 290px;
  }

  .titleSection {
    p {
      width: 90%;
    }
  }

  .box {
    width: 90%;
    margin: 0 auto;

    span {
      width: 90%;
    }
  }

  .formSection {
    flex-direction: column;
  }
}
