.container {
  display: flex;
  flex-direction: column;
  background-color: #333;
  padding: 2rem;
  width: 700px;
  color: #fff;
  background-color: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.048);
  border-radius: 5px;
  min-height: 200px;
}

.content {
  display: flex;
}

.btnSection {
  display: flex;
  gap: 1rem;
  height: 70px;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.iconSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  h1 {
    font-size: 1.5rem;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  width: 50%;

  p {
    font-size: 1.3rem;
  }
}

.icon {
  width: 150px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(91%) contrast(93%);
}

.closeBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

.burnBtn {
  background: rgba(10, 10, 10, 1);
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.199);
  align-self: center;
  text-align: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #2bf1b6;
  }
}

@media (max-width: 768px) {
  .container {
    width: 80%;
    margin: 0 auto;
  }
  .iconSection {
    h1 {
      text-align: center;
    }
  }
  .textSection {
    width: 100%;

    p {
      text-align: center;
    }
  }
  .content {
    flex-direction: column;
    align-items: center;
  }
}
