.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 500px;
  background-color: rgb(27, 27, 27);
  color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  margin: 0 auto;

  h2 {
    font-weight: 200;
    font-size: 1.3rem;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 1rem;
  }

  code {
    color: rgb(42, 169, 238);
    padding: 0.3rem;
  }

  ul {
    margin: 0;
  }
}

.closeBtn {
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(158, 158, 158, 0.541);
  align-self: center;
  text-align: center;
  width: 150px;
  height: 33px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #f12b2b;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    height: 220px;
    border-radius: 10px;
    padding: 1em;
    padding-bottom: 2rem;
  }

  .closeBtn {
    padding: 0.5rem;
  }
}
